<template>
  <el-dialog
      :title="allowJoin==null?'创建班级':'班级设置'"
      custom-class="classes-dialog"
      width="578px"
      top="50px"
      :visible.sync="visible"
      :close-on-click-modal="false"
      :before-close="onClose"
  >
    <div class="classes-dialog__line"></div>
    <div class="classes-dialog__join" v-if="allowJoin!=null">
      <label>允许学生加入班级</label>
      <el-switch v-model="currentAllowJoin"/>
    </div>
    <label class="mt-28">{{ allowJoin==null ? '1.设置' : '' }}入学年份/年级</label>
    <div class="radio-group">
      <div class="title">
        <div class="item-radio"></div>
        <div class="item-year">入学年份</div>
        <div class="item-gradeName">当前年级</div>
      </div>
      <div v-for="grade in gradeList" :key="grade.gradeName" :class="['grade-item', currentEnrollmentYear === grade.year ? 'active' : '']" @click="selectGrade(grade)">
        <div class="item-radio"></div>
        <div class="item-year">{{ grade.year }}</div>
        <div class="item-gradeName">{{ grade.gradeName }}</div>
      </div>
    </div>
    <label class="mt-28">{{ allowJoin==null ? '2.设置' : '' }}班级名称</label>
    <div class="radio-group">
      <template v-if="allowJoin==null">
        <div :class="['radio-item', currentClassNames.includes(item) ? 'radio-item__active' : '']"
             @click="onSelectClassName(item)" v-for="item of classOptions" :key="item">
          {{ item }}
        </div>
      </template>
      <template v-else>
        <div
            :class="['radio-item', currentCustomClassName===''&&item===currentClassName ? 'radio-item__active' : '']"
            @click="onChangeClassName(item)"
            v-for="item of classOptions" :key="item">{{ item }}
        </div>
      </template>
    </div>
    <el-input
        v-model="currentCustomClassName"
        class="classes-dialog__input" maxlength="10" show-word-limit prefix-icon="el-icon-edit"
        placeholder="或自定义输入班级名称" size="large" clearable></el-input>
    <div class="classes-dialog__line"></div>
    <div class="classes-dialog__footer">
      <div :class="{'confirm-disable': btnDisable}" class="classes-dialog__confirm" @click="onConfirm">确定</div>
      <div class="classes-dialog__cancel" @click="onClose">取消</div>
    </div>
  </el-dialog>
</template>

<script>
import {createClass, updateClass, getEnrollmentYears} from "@/api/classes";
export default {
  name: "dialogClass",
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    allowJoin: {
      type: String,
      default: null
    },
    classId: String,
    gradeId: String,
    className: String,
    section: String,
    enrollmentYear: [Number,String],
  },
  data() {
    return {
      currentAllowJoin: null,
      currentClassName: '',
      currentGradeId: '',
      currentClassNames: [],
      currentCustomClassName: '',
      classOptions: ['1班', '2班', '3班', '4班', '5班', '6班', '7班', '8班', '9班', '10班', '11班', '12班'],
      gradeList: [],
      schoolSection: sessionStorage.getItem('schoolSection'),
      yearSystem: sessionStorage.getItem('yearSystem'),
      currentEnrollmentYear: ''
    }
  },
  computed: {
    btnDisable() {
      return !(this.currentEnrollmentYear && (this.currentClassNames.length || this.currentClassName || this.currentCustomClassName?.trim()))
    }
  },
  created() {
    if (this.allowJoin != null) this.currentAllowJoin = this.allowJoin === '1'
    this.currentEnrollmentYear = this.enrollmentYear ? this.enrollmentYear : ''
    this.currentGradeId = this.gradeId
    if (this.classOptions.includes(this.className)) {
      this.currentClassName = this.className
    } else {
      this.currentCustomClassName = this.className
    }
    this.getEnrollmentYears()
  }
  ,
  methods: {
    onChangeClassName(val) {
      this.currentCustomClassName = ''
      this.currentClassName = val
    },
    /**
     * 新建时多选
     * @param val
     */
    onSelectClassName(val) {
      if (this.currentClassNames.includes(val)) {
        this.currentClassNames = this.currentClassNames.filter(item => item !== val)
      } else {
        this.currentClassNames.push(val)
      }
    },
    // 获取入学年份/年级
    async getEnrollmentYears() {
      try {
        const data = {
          section: this.schoolSection,
          yearSystem: this.yearSystem
        }
        const res = await getEnrollmentYears(data)
        if (res.state === '11') {
          this.gradeList = res.data
        }
      } catch (e) {
      }
    },
    // 选择入学年份/年级
    selectGrade(grade) {
      this.currentEnrollmentYear = grade.year
      this.currentGradeId = grade.gradeId
    },
    async onConfirm() {
      if (this.btnDisable) return
      try {
        let res
        if (this.allowJoin == null) { // 新建
          // 如果自定义班级并且没在班级数组中，就丢进去
          if (this.currentCustomClassName?.trim() && !this.currentClassNames.includes(this.currentCustomClassName)) {
            this.currentClassNames.push(this.currentCustomClassName)
          }
          const data = this.currentClassNames.map(val => {
            return {
              name: val,
              gradeId: this.currentGradeId,
              year: new Date().getFullYear(),
              enrollmentYear: this.currentEnrollmentYear
            }
          })
          res = await createClass(JSON.stringify(data))
        } else {
          const className = this.currentCustomClassName ? this.currentCustomClassName : this.currentClassName
          res = await updateClass(this.classId, className, this.currentAllowJoin ? 1 : 0, this.currentGradeId, this.currentEnrollmentYear)
        }
        if (res.state === '11') {
          if (this.allowJoin == null) {
            this.$message({
              dangerouslyUseHTMLString: true,
              message: res.data,
              type: 'success'
            })
          } else {
            this.$message.success('班级信息修改成功')
          }

          this.$emit('updated')
          this.onClose()
        } else {
          this.$message.error(res.data)
        }
      } catch (e) {
        this.$message.error(res.data)
      }
    },
    onClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.classes-dialog {
  border-radius: 16px;

  .el-dialog__body {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .el-input__inner {
    color: #309af2;
    font-size: 17px;
    border: 1px solid #C8C8C8;
    background-color: #f6f6f6;
  }

  .el-input__count-inner {
    background: #f6f6f6 !important;
  }
}
</style>
<style lang="scss" scoped>
.classes-dialog {

  label {
    color: #333333;
    font-size: 20px;
    font-weight: bold;
    margin-left: 28px;
  }

  &__join {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 28px;
    margin-top: 28px;
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 86px;
  }

  &__confirm {
    width: 150px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    color: #FFFFFF;
    font-size: 15px;
    cursor: pointer;
    background: linear-gradient(90deg, #6DBBFC 0%, #309AF2 100%);
    border-radius: 4px;

    &:hover {
      background: linear-gradient(90deg, #309AF2 0%, #309AF2 100%);
    }
  }

  &__cancel {
    width: 150px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    color: #309af2;
    font-size: 15px;
    cursor: pointer;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #309AF2;
    margin-left: 56px;

    &:hover {
      background-color: #F1F1F1;
    }
  }

  &__input {
    width: 522px;
    margin: 14px 28px 28px;
  }

  &__line {
    width: 100%;
    height: 1px;
    background-color: #F1F1F1;
  }

  .mt-28 {
    margin-top: 28px;
  }
}

.radio-group {
  display: flex;
  flex-wrap: wrap;
  padding-left: 28px;

  .radio-item {
    min-width: 120px;
    height: 36px;
    line-height: 36px;
    border-radius: 4px;
    color: #666666;
    text-align: center;
    cursor: pointer;
    border: 1px solid #C8C8C8;
    background-color: #F6F6F6;
    padding: 0 14px;
    margin-top: 14px;
    margin-right: 14px;

    &:hover {
      color: #333333;
    }
  }

  .radio-item__active {
    background-color: #EAF4FD;
    color: #309AF2;
    border-color: #309AF2;

    &:hover {
      color: #309AF2;
    }
  }

  .title,.grade-item {
    height: 50px;
    line-height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    .item-radio{
      width: 20px;
      height: 20px;
      margin-left: 80px;
      margin-right: 15px;
    }
  }
  .title{
    .item-year,.item-gradeName{
      border-bottom: 1px solid #F1F1F1;
      color: #666666;
      font-size: 15px;
      font-weight: bold;
      width: 174px;
      height: 50px;
    }
  }
  .grade-item{
    .item-radio{
      background-image: url("../../../assets/check-icon2.png");
      background-size: 100% 100%;
    }
    .item-year,.item-gradeName{
      font-size: 16px;
      color: #999999;
      width: 174px;
      height: 50px;
    }
    &.active{
      .item-radio{
        background-image: url("../../../assets/selected.png");
        background-size: 100% 100%;
      }
      .item-year{
        font-size: 17px;
        color: #333333;
        font-weight: bold;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.03) 100%);
      }
      .item-gradeName{
        font-size: 17px;
        color: #333333;
        font-weight: bold;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0) 100%);
      }
    }
  }
}

.confirm-disable {
  cursor: not-allowed;
  background: #C0E0FB !important;
}
</style>
